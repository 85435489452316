import { useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, Icon, ProgressBar, Table } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  listObject,
  listRoute,
  resetList,
  updateMiniExplorer,
} from '_common/components/MiniExplorer/miniExplorerSlice';
import Cell from '_common/components/Table2/Cells';
import { updateData } from 'App/redux/appSlice';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';

import Header from './Header';

import styles from './MiniExplorer.module.scss';

import type {
  ColumnProps,
  TableProps,
} from 'dodoc-design-system/build/types/Components/Table/Table';

type TableValue = { id: string; type?: string; object_status?: string }[];

const MiniExplorer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const data = useSelector((state) => state.app.data);
  const { current, view, selected, list, hasNextPage, operatedObjIds } = useSelector(
    (state) => state.miniExplorer,
  );
  const document = useSelector(getDocumentObject);

  const [loading, setLoading] = useState(false);

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        width: 296,
        flex: true,
      },
      {
        id: 'status',
        width: 136,
      },
    ];
  }, []);

  useEffect(() => {
    handleNewList();
    return () => {
      dispatch(updateMiniExplorer({ isCreatingFolder: false }));
    };
  }, []);

  useEffect(() => {
    dispatch(resetList());
    handleNewList();
  }, [current?.id, view]);

  const handleNewList = () => {
    if (!view && current) {
      setLoading(true);
      dispatch(listObject({ objectId: current.id })).then(() => {
        if (document) {
          dispatch(updateData({ objectId: document.id, data: { citations: document.citations } }));
        }
        setLoading(false);
      });
    } else if (view) {
      setLoading(true);
      dispatch(listRoute({ route: view })).then(() => setLoading(false));
    }
  };

  const handleClick: TableProps<TableValue>['onRowDoubleClick'] = ({ data }) => {
    const { id, type, object_status } = data;
    if ((type === 'folder' && object_status !== 'broken') || type === 'space') {
      if (!operatedObjIds?.includes(id)) {
        dispatch(
          updateMiniExplorer({
            selected: selected === id ? undefined : id,
          }),
        );
      }
    }
  };
  const handleDoubleClick: TableProps<TableValue>['onRowDoubleClick'] = ({ data }) => {
    const { id, type, object_status } = data;

    if ((type === 'folder' && object_status !== 'broken') || type === 'space') {
      if (!operatedObjIds?.includes(id)) {
        dispatch(
          updateMiniExplorer({ selected: undefined, view: undefined, current: { id, type } }),
        );
      }
    }
  };

  const value = useMemo<TableValue>(() => {
    return list?.map((objectId: string) => {
      const object = data[objectId];
      const isDisabled =
        operatedObjIds?.includes(object.id) ||
        (object.type !== 'folder' && object.type !== 'space') ||
        object.status === 'broken';
      return {
        id: object.id,
        type: object.type,
        object_status: object.status,
        name: (
          <Cell testId={`${objectId}-name-column`} disabled={isDisabled}>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        status: (
          <Cell testId={`${objectId}-status-column`} disabled={isDisabled}>
            <span className={styles.colStatus}>
              <Cell.ObjectStatus object={object} testId="object-status" />
            </span>
          </Cell>
        ),
        suffix: isDisabled ? (
          <Cell testId={`${objectId}-suffix-column`}>
            <Icon size={16} icon="InformationBlue" />
          </Cell>
        ) : null,
      };
    });
  }, [list, data]);

  const renderList = () => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <ProgressBar
            label={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            testId="miniExplorer-progressBar"
          />
        </div>
      );
    }
    if (list.length === 0) {
      return (
        <div className={styles.empty}>
          <EmptyState
            size="large"
            icon="EmptyFolder"
            title={intl.formatMessage({ id: 'storage.browserHeader.folderEmpty' })}
            testId="empty-folder"
          >
            <FormattedMessage id="storage.modals.move.emptyFolder" />
          </EmptyState>
        </div>
      );
    }
    return (
      <Table
        columns={columns}
        value={value}
        onRowDoubleClick={handleDoubleClick}
        onRowClick={handleClick}
        selection={selected ? [{ id: selected }] : undefined}
        withHeader={false}
        flex={false}
        maxHeight={'30rem'}
        virtualScrollerOptions={
          import.meta.env.MODE === 'test'
            ? undefined
            : {
                itemSize: 48,
                lazy: true,
                onLazyLoad: (e) => {
                  const { last } = e;
                  if (!view && current) {
                    if (last === value.length && hasNextPage) {
                      dispatch(listObject({ objectId: current.id }));
                    }
                  } else if (view) {
                    if (last === value.length && hasNextPage) {
                      dispatch(listRoute({ route: view }));
                    }
                  }
                },
              }
        }
        testId="objects"
      />
    );
  };

  return (
    <div className={styles.root}>
      <Header />
      <div className={`${styles.body}`}>{renderList()}</div>
    </div>
  );
};

export default MiniExplorer;
