import { useState, useRef, useEffect, memo, MouseEventHandler, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, usePopper, Button, Toggle } from 'dodoc-design-system';

import { useSelector, useIsNodeClamped } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { stringToRichText } from 'utils';

import {
  selectReadOnlyMode,
  getDocumentObject,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { selectCollaborators } from 'App/redux/appSlice';

import { UsernameLabel, FormattedDate, FormattedTime } from '_common/components';

import { SuiteAvatar } from '_common/suite/components';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';

import TrackedActionBody, { getContentValue, CONTENT_TEXT_LABELS } from './TrackedActionBody';

import styles from './TrackedActionCard.module.scss';
import { LikeToggle, ReplyList, ReplyToggle } from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';

type PriorityIconProps = {
  HIGH: {
    icon: 'High';
  };
  MEDIUM: {
    icon: 'Medium';
  };
  LOW: {
    icon: 'Low';
  };
};

const BUTTON_PRIORITY_ICONS: PriorityIconProps = {
  HIGH: { icon: 'High' },
  MEDIUM: { icon: 'Medium' },
  LOW: { icon: 'Low' },
};
const PRIORITY: PriorityIconProps = {
  HIGH: { icon: 'High' },
  MEDIUM: { icon: 'Medium' },
  LOW: {
    icon: 'Low',
  },
};

const NO_CONTENT_ACTIONS = [
  'ADD_PAGEBREAK',
  'ADD_TABLE',
  'ADD_IMAGE',
  'ADD_TABLE_CONTENTS',
  'ADD_LIST_FIGURES',
  'ADD_LIST_TABLES',
  'DELETE_FIGURE',
];

type PriorityProps = 'MEDIUM' | 'LOW' | 'HIGH';

type TrackedActionProps = {
  author: string;
  user?: string;
  comments: Editor.Reply[];
  content:
    | {
        [key: string]: {
          type: string;
          value: string;
        };
      }
    | string;
  id: string;
  location?: string[];
  nodesPermissions?: {
    users: {
      [id: string]: string[];
    };
  };
  priority: PriorityProps;
  status: string;
  time: ISODate;
  type: Editor.TrackedAction['type'];
  votes: {
    user: string;
    value: number;
    time: string;
  }[];
  number?: number;
  deleted_replies?: string[];
  application?: string;
};

export type TrackedActionCardProps = {
  className?: string;
  selected?: boolean;
  panel?: boolean;
  trackedAction: TrackedActionProps;
  testId: string;
};

const TrackedActionCardContent = memo<TrackedActionCardProps>(
  ({ className, selected, panel = false, trackedAction, testId }) => {
    const intl = useIntl();

    const contentRef = useRef(null);

    const isPageLayout = useSelector(selectIsPageLayout);
    const isReadOnlyMode = useSelector(selectReadOnlyMode) || isPageLayout;
    const userId = useSelector((state) => state.auth.userId);
    const doc = useSelector(getDocumentObject);
    const collaborators = useSelector((state) => selectCollaborators(state, doc.id));

    const [readMore, setReadMore] = useState(true);
    const [showReplies, setShowReplies] = useState(false);

    const [contentValue, setContentValue] = useState<
      {
        type: string;
        value: string;
      }[]
    >([]);

    const isClamped = useIsNodeClamped({
      ref: contentRef,
      clamp: 3,
      dependencies: [
        trackedAction.content,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.inserted?.value,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.deleted?.value,
        contentValue,
      ],
    });

    const isAuthor = trackedAction.author === userId;
    const isOwner =
      doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');
    const isCommentator =
      doc.user_permissions.includes('comment') ||
      (trackedAction.nodesPermissions &&
        trackedAction.nodesPermissions.users[userId] &&
        trackedAction.nodesPermissions.users[userId].includes('comment'));
    const hasPermissions = (!isAuthor && isOwner) || (isAuthor && (isOwner || isCommentator));
    const canChangePriority = isAuthor || isOwner;
    // Reply to a suggestion: Suggestion Creator, Comment permissions, or user with owner permission
    const canReply = isAuthor || doc.user_permissions.includes('comment') || isOwner;

    const priorityPopper = usePopper({
      disabled: isReadOnlyMode || !canChangePriority,
      placement: 'bottom-end',
    });

    //#region Data parsing
    const parseReply = (originalReply: Editor.Reply): Card.Reply => {
      const reply: Card.Reply = {
        id: originalReply.id,
        authorId: originalReply.author,
        creationDate: originalReply.time,
        modificationDate: '',
        votes: originalReply.votes,
        content: { dir: 'ltr', content: stringToRichText(originalReply.content) },
      };

      return reply;
    };

    const parsedSuggestion = useMemo<Card.Suggestion>(
      () => ({
        id: trackedAction.id,
        replies: trackedAction.comments.map((reply) => parseReply(reply)),
      }),
      [trackedAction],
    );
    //#endregion

    useEffect(() => {
      if (!isClamped) {
        setReadMore(false);
      }
    }, [isClamped]);

    useEffect(() => {
      setShowReplies(trackedAction.comments?.length > 0);
    }, [trackedAction.id]);

    useEffect(() => {
      setContentValue(
        getContentValue({ content: trackedAction.content, type: trackedAction.type }),
      );
    }, [trackedAction.id, trackedAction.content]);

    const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
      /**
       * Stop propagation to avoid card selection
       * Card selection will invoke scrollIntoView and app might pause to load new content
       */
      e.stopPropagation();

      setReadMore(!readMore);
    };

    const handleVoteTrackedAction: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId) {
        EditorManager.getInstance().voteTrackedActionReply(
          trackedAction.id,
          replyId,
          currentUserLiked ? false : true,
        );
      } else {
        EditorManager.getInstance().voteTrackedAction(
          trackedAction.id,
          currentUserLiked ? false : true,
        );
      }
    };

    const handleAcceptClicked = () => {
      EditorManager.getInstance().focusTrackedAction(trackedAction.id, () => {
        EditorManager.getInstance().acceptTrackedAction(trackedAction.id);
      });
    };

    const handleTrackedActionSelected = () => {
      if (!selected) {
        EditorManager.getInstance().focusTrackedAction(trackedAction.id);
      }
    };

    const handleRejectClicked = () => {
      EditorManager.getInstance().focusTrackedAction(trackedAction.id, () => {
        EditorManager.getInstance().rejectTrackedAction(trackedAction.id);
      });
    };

    const handleChangePriority = (priority: PriorityProps) => {
      if (trackedAction.status === 'OPEN') {
        EditorManager.getInstance().changeTrackedActionPriority(trackedAction.id, priority);
      }
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };

    //#region Handle replies
    const isReplyAuthor = (reply: Card.Reply) => reply.authorId === userId;

    const handleCreateReply = (reply: string) => {
      EditorManager.getInstance().replyTrackedAction(parsedSuggestion.id, stringToRichText(reply));
    };

    const handleDeleteReply = (replyId: string) => {
      EditorManager.getInstance().deleteTrackedActionReply(parsedSuggestion.id, replyId);
    };

    const handleEditReply = ({ replyId, newContent }: { replyId: string; newContent: string }) => {
      EditorManager.getInstance().editTrackedActionReply(
        parsedSuggestion.id,
        replyId,
        stringToRichText(newContent),
      );
    };

    const handleVoteReply: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId && trackedAction.status !== 'd') {
        EditorManager.getInstance().voteTrackedActionReply(
          trackedAction.id,
          replyId,
          !currentUserLiked,
        );
      }
    };

    const canEditReply = (replyId: string) => {
      const reply = parsedSuggestion.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return isReplyAuthor(reply) && !isReadOnlyMode;
      }
      return false;
    };

    const canDeleteReply = (replyId: string) => {
      const reply = parsedSuggestion.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return (isOwner || (isReplyAuthor(reply) && hasPermissions)) && !isReadOnlyMode;
      }

      return false;
    };

    const canVoteReply = () => {
      return !isReadOnlyMode;
    };
    //#region
    //#endregion

    const renderHeader = () => {
      return (
        <div style={{ display: 'flex' }}>
          <SuiteAvatar
            margin="0 1rem 0 0"
            userId={trackedAction.author || 'IMPORTED_USER'}
            name={trackedAction.user}
          />
          <div className={styles.labels}>
            <div className={styles.author}>
              <UsernameLabel userId={trackedAction.author} name={trackedAction.user} />
              <PageLayoutTooltip
                type="trackedAction"
                content={intl.formatMessage({
                  id:
                    isReadOnlyMode || !canChangePriority
                      ? 'CHANGE_PRIORITY_CARD'
                      : 'global.priority',
                })}
                testId={`tracked-action-card-${trackedAction.id}-priority-tooltip`}
              >
                <span>
                  <Toggle
                    variant="link"
                    disabled={isReadOnlyMode || !canChangePriority}
                    style={{ marginLeft: '1rem', padding: 0 }}
                    size="small"
                    isToggled={priorityPopper.isOpen}
                    {...BUTTON_PRIORITY_ICONS[trackedAction.priority]}
                    {...priorityPopper.referenceProps}
                    testId={`tracked-action-card-${trackedAction.id}-priority-toggle`}
                  />
                </span>
              </PageLayoutTooltip>
              <Dropdown
                {...priorityPopper.popperProps}
                testId={`tracked-action-card-${trackedAction.id}-priority-dropdown`}
              >
                <Dropdown.Item
                  prefixIcon={PRIORITY.HIGH.icon}
                  onClick={() => handleChangePriority('HIGH')}
                  testId={`tracked-action-card-${trackedAction.id}-high-dropdown-item`}
                >
                  <FormattedMessage id="editor.sidebar.review.filter.priority.high" />
                </Dropdown.Item>
                <Dropdown.Item
                  prefixIcon={PRIORITY.MEDIUM.icon}
                  onClick={() => handleChangePriority('MEDIUM')}
                  testId={`tracked-action-card-${trackedAction.id}-medium-dropdown-item`}
                >
                  <FormattedMessage id="editor.sidebar.review.filter.priority.medium" />
                </Dropdown.Item>
                <Dropdown.Item
                  prefixIcon={PRIORITY.LOW.icon}
                  onClick={() => handleChangePriority('LOW')}
                  testId={`tracked-action-card-${trackedAction.id}-low-dropdown-item`}
                >
                  <FormattedMessage id="editor.sidebar.review.filter.priority.low" />
                </Dropdown.Item>
              </Dropdown>
            </div>
            <div className={styles.time}>
              <FormattedDate date={trackedAction.time} type="short" />
              &nbsp;
              <FormattedTime time={trackedAction.time} type="meridiem" />
            </div>
          </div>
        </div>
      );
    };

    const renderReadMore = () => {
      return (
        <div className={styles.readMore} onClick={handleToggleReadMore}>
          <FormattedMessage
            id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
          />
        </div>
      );
    };

    const renderBody = () => {
      if (NO_CONTENT_ACTIONS.includes(trackedAction.type)) {
        return (
          <>
            {trackedAction.application && (
              <div className={styles.imported}>
                <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
              </div>
            )}
            <div className={`${styles.text} ${styles.bold}`}>
              {trackedAction.type ? (
                <FormattedMessage id={CONTENT_TEXT_LABELS[trackedAction.type]} />
              ) : (
                <FormattedMessage id="global.change" />
              )}
            </div>
          </>
        );
      }

      return (
        <>
          {trackedAction.application && (
            <div className={styles.imported}>
              <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
            </div>
          )}
          <span
            ref={contentRef}
            style={{ lineHeight: '2rem' }}
            className={` ${!readMore && styles.readLess}`}
          >
            <TrackedActionBody
              type={trackedAction.type}
              content={contentValue}
              contentRef={contentRef}
            />
          </span>

          {isClamped && renderReadMore()}
        </>
      );
    };

    const renderFooter = () => {
      const isAuthor = userId === trackedAction.author;
      const isOwner =
        doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');

      // Accept a suggestion: User with owner permission.
      const canAccept = isOwner;
      // Reject a suggestion: Suggestion Creator or user with Owner permission
      const canReject = isAuthor || isOwner;

      return (
        <>
          <Card.Footer.Left>
            <PageLayoutTooltip
              type="comment"
              testId={`comment-card-${trackedAction.id}-like-tooltip`}
            >
              <LikeToggle
                votes={trackedAction.votes ?? []}
                target="comment"
                onVote={handleVoteTrackedAction}
                disabled={isReadOnlyMode}
                testId={testId}
              />
            </PageLayoutTooltip>
            <PageLayoutTooltip
              type="task"
              disabled={!!parsedSuggestion.replies?.length || undefined}
              testId={`task-card-${parsedSuggestion.id}-reply-tooltip`}
            >
              <ReplyToggle
                repliesCount={parsedSuggestion.replies?.length ?? 0}
                isToggled={showReplies}
                onToggleReplies={handleToggleReplies}
                canComment={canReply}
                id={`task-${parsedSuggestion.id}-replyToggle`}
                testId={testId}
              />
            </PageLayoutTooltip>
          </Card.Footer.Left>
          <PageLayoutTooltip
            type="trackedAction"
            disabled={isPageLayout ? undefined : isOwner}
            content={intl.formatMessage({ id: 'ACCEPT_CHANGE' })}
            testId={`tracked-action-card-${trackedAction.id}-accept-button-tooltip`}
          >
            <div>
              <Button
                variant="link"
                disabled={!canAccept || isReadOnlyMode}
                size="small"
                onClick={handleAcceptClicked}
                testId={`tracked-action-card-${trackedAction.id}-accept-button`}
                margin="0 0.5rem 0 0"
              >
                <FormattedMessage id="ACCEPT" />
              </Button>
            </div>
          </PageLayoutTooltip>
          <PageLayoutTooltip
            type="trackedAction"
            disabled={isPageLayout ? undefined : isAuthor || isOwner}
            content={intl.formatMessage({ id: 'REJECT_CHANGE' })}
            testId={`tracked-action-card-${trackedAction.id}-reject-button-tooltip`}
          >
            <div>
              <Button
                variant="link"
                disabled={!canReject || isReadOnlyMode}
                size="small"
                onClick={handleRejectClicked}
                testId={`tracked-action-card-${trackedAction.id}-reject-button`}
              >
                <FormattedMessage id="REJECT" />
              </Button>
            </div>
          </PageLayoutTooltip>
        </>
      );
    };

    return (
      <Card
        testId={`TrackedAction#${trackedAction.id}`}
        sidebar={panel}
        selected={selected}
        onClick={panel ? handleTrackedActionSelected : () => {}}
        width={panel ? '100%' : '43rem'}
      >
        <Card.Header size="medium">{renderHeader()}</Card.Header>
        <Card.Body>{renderBody()}</Card.Body>
        <Card.Footer size="large">{renderFooter()}</Card.Footer>
        {showReplies && (
          <ReplyList
            replies={parsedSuggestion.replies ?? []}
            testId={`${testId}-replyList`}
            commentId={parsedSuggestion.id}
            collaborators={collaborators}
            canComment={canReply}
            newReplyInteraction={{ environment: 'editor' }}
            optionsToggleTooltip={{
              content: intl.formatMessage({
                id: 'CANNOT_MAKE_CHANGES_TO_TRACK_CHANGES_IN_PAGE_LAYOUT',
              }),
              disabled: !isPageLayout,
              testId: `suggestion-card-${parsedSuggestion.id}-reply-options-tooltip`,
            }}
            likeToggleTooltip={{
              content: intl.formatMessage({
                id: 'CANNOT_MAKE_CHANGES_TO_TRACK_CHANGES_IN_PAGE_LAYOUT',
              }),
              disabled: !isPageLayout,
              testId: `suggestion-card-${parsedSuggestion.id}-reply-like-tooltip`,
            }}
            createReply={handleCreateReply}
            editReply={handleEditReply}
            deleteReply={handleDeleteReply}
            voteReply={handleVoteReply}
            canEditReply={canEditReply}
            canDeleteReply={canDeleteReply}
            canVoteReply={canVoteReply}
          />
        )}
      </Card>
    );
  },
);

export default TrackedActionCardContent;
