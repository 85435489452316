import { Tooltip } from 'dodoc-design-system';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import RemoveIcon from './RemoveIcon';
import { useTableOptionsContext } from './TableOptionsContext';
import styles from './TableOptions.module.scss';

type RemoverProps = {
  type: 'row' | 'column';
  onClick: () => void;
  offset: number;
  size: number;
  multiple: boolean;
  testId: string;
};

const Remover = ({ type, onClick, offset, size, multiple, testId }: RemoverProps) => {
  const intl = useIntl();
  const { setDeleting } = useTableOptionsContext();

  const style = useMemo(() => {
    if (type === 'column') {
      return {
        left: `${offset}px`,
        width: `${size}px`,
        bottom: 'calc(100% + 4px)',
      };
    }
    return {
      top: `${offset}px`,
      height: `${size}px`,
      right: 'calc(100% + 4px)',
    };
  }, [type, offset, size]);

  const tooltipContentId = multiple
    ? 'DELETE_SELECTION'
    : type === 'column'
      ? 'DELETE_COLUMN'
      : 'DELETE_ROW';

  return (
    <div className={styles['remover-container']} style={style}>
      <div
        className={styles.remover}
        onMouseEnter={() => setDeleting(true)}
        onMouseLeave={() => setDeleting(false)}
        onClick={onClick}
      >
        <Tooltip
          content={intl.formatMessage({ id: tooltipContentId })}
          testId={`${testId}-tooltip`}
        >
          <RemoveIcon />
        </Tooltip>
      </div>
    </div>
  );
};

export default Remover;
