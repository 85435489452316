import { createDraftSafeSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import objectApi from 'App/redux/objectApi';
import { InitialState, Modals } from './ModalsSliceTypes';

export const initialState = {
  open: {},

  // #region Roche
  ElementStatusFormModal: { mode: 'create', id: null },
  DeleteElementStatusModal: { id: null },
  ChangeElementStatusModal: { id: null, submitting: false },
  EditMetadata: { id: null },
  // #endregion

  // #region App
  PublicLinkModal: {},
  CheckInModal: {
    loading: false,
    operation: '',
    file: {},
    errors: {},
    id: '',
    objectId: null,
    uploadPercentage: null,
  },
  ConfirmationModal: {
    size: 75,
    headerType: 'information',
    title: '',
    titleValues: {},
    message: '',
    messageValues: {},
    messageChildren: undefined,
    confirmButtonTextId: 'global.confirm',
    confirmButtonTextValues: {},
    confirmButtonType: 'primary',
    cancelButtonTextId: 'global.cancel',
    cancelButtonTextValues: {},
    cancelButtonShow: true,
    cancelButtonAction: false,
    actionCode: '',
    actionValue: {},
    persistent: true,
  },
  SaveAsModal: { objectIds: [] },
  RenameObjectModal: {
    objectId: [],
    submitting: false,
  },
  MoveModal: {},
  ShareModal: { view: 'users', selected: null },
  UploadTagsModal: {
    state: 'standby', // can be standby, uploading, duplicated, failed
  },
  AddAffiliationModal: {
    operation: '',
    field: '',
  },
  TemplateActionModal: {
    action: 'installing',
    total: 1,
    current: 1,
    name: '',
  },
  ExtensionUpdateInformationModal: {
    type: 'template',
    id: null,
    name: '',
  },
  ExtensionErrorsModal: {
    list: [],
    updating: false,
  },
  DifferentTimezoneWarningModal: {},
  BrowserSupportWarningModal: {},
  CreateNewObjectModal: {
    title: '',
    nameLabel: '',
    namePlaceholder: '',
    descriptionLabel: '',
    descriptionPlaceholder: '',
    action: '',
    actionValues: {},
  },
  SupportFormModal: {},
  UploadProgressModal: {
    name: '',
    title: undefined,
    size: 0,
    percentage: 0,
  },
  CreateGroupModal: {},
  ChangeAvatarModal: {},
  ChangeEmailModal: {},
  ChangeNameModal: {
    disabledFields: {
      firstName: false,
      lastName: false,
    },
  },
  GenericSettingModal: {
    header: '',
    label: '',
    inputPlaceholder: '',
    originalValue: '',
    inputType: 'text',
    buttons: [],
    data: {},
    type: '',
    action: '',
    actionValues: {},
  },
  LanguageSettingModal: {},
  EditTimezoneModal: {},
  ChangePasswordModal: {},
  ChangeManagerModal: { name: '', data: {} },
  CreateNewTagModal: {},
  NewFolderDocumentModal: { id: null, newType: null, file: {}, current: {} },
  UserManagementFormModal: {
    title: 'ADD_USER_TO_THE_TENANT',
    submit: 'global.addUser',
    user: null,
  },
  UploadUsersModal: {},
  UserTokensModal: {
    user: null,
  },
  WOPIRedirectionModal: { app: null, action: null, id: null },
  AboutModal: {},
  // #endregion
  // #region Editor
  CitationsModal: {
    documentId: null,
    library: {
      selected: null,
    },
    search: {
      loading: false,
      list: [],
      dict: {},
    },
    import: {
      dropzone: true,
      loading: false,
      list: [],
      dict: {},
    },
    navigation: {
      current: null,
      identity: 'storage',
    },
  },
  CustomSpacingModal: {
    spacing: {
      lineHeight: '',
      paddingTop: '',
      paddingBottom: '',
    },
  },
  EditorErrorModal: {
    code: null,
    message: null,
    title: null,
  },
  ExportDocumentModal: {
    initialExportFormat: 'docx',
    exportType: 'simple', //TODO:TS this should be related with ExportIntegrationModalTypes ExportType
    exporting: false,
    objectId: null,
  },
  DocumentDetailsModal: {},
  ExportIntegrationModal: {
    error: false,
    exportTemplate: {},
    waitingValidation: false,
  },
  RenameDocumentStylesModal: {
    name: '',
  },
  ApproveBlock: {
    list: [],
    state: 'single',
  },
  ReopenBlock: {
    list: [],
  },
  ClipboardInfoModal: {},
  ClipboardInstallExtensionModal: {},
  SuggestionModeLockConfirmationModal: {},
  SuggestionModeLockInfoModal: {},
  SetLanguageModal: { onSelection: null },
  TaskWatchModal: { tag: null },
  CaptionsModal: { editMode: null },
  EquationsModal: { isEdit: null, equation: null },
  NewListModal: {
    customize: false,
    type: '',
  },
  IndentationOptionsModal: {
    indentation: {
      leftIndentation: '',
      rightIndentation: '',
      specialIndent: '',
      specialIndentValue: '',
    },
  },
  MissingFontsModal: {},
  KeyboardShortcutsModal: {},
  TablePropertiesModal: {},
  SynonymsModal: {
    query: '',
  },
  CharmapModal: {},
  HyperlinkModal: {
    url: '',
    textToDisplay: '',
    showTextToDisplay: false,
    links: [],
    edit: false,
  },
  UpdateCrossReferencesModal: {
    single: false,
    all: false,
  },
  EditCrossReferencesModal: {},
  LikesModal: { votes: [] },
  ColorPickerModal: { attribute: '', action: '', actionValues: {}, data: null },
  OpenHyperlinkModal: { url: null },
  TabulationsModal: {},
  WordCountModal: {},
  WrapTextOptionsModal: {},
  // #endregion
  MetadataInconsistenciesModal: { errors: {} },
  PublishVeevaModal: { id: null },
  ImportNewVersionModal: { id: null, jobId: null },
  ReplaceReferenceLibraryModal: { id: null, objectId: null, name: '' },
  RenameEndNoteFileModal: {
    title: '',
    actionButtonLabel: '',
    type: '',
    citations: '',
    target: [],
  },
  ReplaceAllModal: {},
  SplitCellsModal: {},
  DeleteCellsModal: {},
  DocumentAuditLogModal: {
    filters: [],
    objectId: '',
    objectType: null,
    isVisible: undefined,
  },
  PageSetupModal: {
    file: true,
    properties: {},
  },
  //#region PDF
  PPTConfirmationModal: {},
  //#endregion
} as InitialState;

// Make sure to check `index.d.ts` to be aware of defined types
const ModalsSlice = createSlice({
  name: 'MODALS',
  // To avoid listing all the modals in initialState.open, this type takes care of typing that `open` object
  initialState: initialState as InitialState,
  reducers: {
    openModal: (state, action: PayloadAction<Modals>) => {
      state.open[action.payload] = true;
    },
    openAndUpdateModal: (state, action: PayloadAction<{ modal: Modals; data: any }>) => {
      state.open[action.payload.modal] = true;
      state[action.payload.modal] = action.payload.data;
    },
    closeModal: (state, action: PayloadAction<Modals>) => {
      state.open[action.payload] = false;
    },
    closeAndResetModal: (state, action: PayloadAction<Modals>) => {
      state.open[action.payload] = false;
      state[action.payload] = initialState[action.payload];
    },
    updateModal: (state, action: PayloadAction<{ modal: Modals; data: any; mode?: 'replace' }>) => {
      if (action.payload.mode === 'replace') {
        state[action.payload.modal] = action.payload.data;
      }
      state[action.payload.modal] = { ...state[action.payload.modal], ...action.payload.data };
    },
    closeAllModals: (state) => {
      // TODO: Consider returnin the initialState to reset all the modals state when closing them
      state.open = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(objectApi.endpoints.changeElementStatus.matchPending, (state) => {
      state.ChangeElementStatusModal.submitting = true;
    });
    builder.addMatcher(
      isAnyOf(
        objectApi.endpoints.changeElementStatus.matchFulfilled,
        objectApi.endpoints.changeElementStatus.matchRejected,
      ),
      (state) => {
        state.ChangeElementStatusModal.submitting = false;
      },
    );
  },
});

// export const isModalOpen = createSelector([state => state.modals, (_,modal) => modal], (state, modal) => {})
export const isModalOpen = createDraftSafeSelector(
  [(state) => state, (_: InitialState, modal: Modals) => modal],
  (state, modal) => {
    return !!state.open[modal];
  },
);

// Extract and export each action creator by name
export const {
  openModal,
  closeModal,
  openAndUpdateModal,
  closeAndResetModal,
  updateModal,
  closeAllModals,
} = ModalsSlice.actions;
// Export the reducer, either as a default or named export
export default ModalsSlice.reducer;
