import React from 'react';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';
import RowErrorBoundary from '_common/components/Tables/RowErrorBoundary/RowErrorBoundary';
import Footer from '../Footer/Footer';
import Header from './Header/Header';
import styles from './List.module.scss';

const LAZY_MIN_LIMIT = 200;
const LAZY_THRESHOLD = 50;

const ROW_HEIGHT = 48;

class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderRow = this.renderRow.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  isRowLoaded({ index }) {
    const { objects } = this.props;
    return !objects.hasNextPage || index < objects.list.length;
  }

  renderHeader() {
    const { header, columns, objects, updateOrder } = this.props;

    if (header) {
      if (header.Component) {
        const { Component, props } = header;
         
        return <Component {...props} />;
      }
      return (
        <Header
          columns={columns}
          order={{
            field: objects.request ? objects.request.order_field : '',
            type: objects.request ? objects.request.order_type : '',
          }}
          updateOrder={updateOrder}
        />
      );
    }

    return undefined;
  }

  renderRow({ index, key, style }) {
    const { objects, row, userPermissions } = this.props;
    const { list, dict } = objects;
    const object = dict[list[index]];
    const newKey = `${key}-${object ? object.id : `error-${index}`}`;

    /* if (!object) {
      return <div key={newKey} style={style} />;
    } */

    const hasSettingsPermission = userPermissions ? userPermissions.spacePermission : false;
    if (row) {
      const { Component, props } = row;
      return (
        <RowErrorBoundary
          key={newKey}
          style={style}
          rowProps={{ rowIndex: index, object, ...props }}
        >
          <Component
            style={style}
            object={object}
            rowIndex={index}
            objects={objects}
            hasSettingsPermission={hasSettingsPermission}
             
            {...props}
          />
        </RowErrorBoundary>
      );
    }
    return null;
  }

  render() {
    const { style, objects, loadRows, accounts, footer, publicProfiles, resetScroll } = this.props;
    const { list } = objects;
    const rowCount = objects.hasNextPage ? list.length + 1 : list.length;
    return (
      <div className={styles.root} style={style}>
        {this.renderHeader()}
        <div className={styles.content}>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={objects.isNextPageLoading ? () => {} : loadRows}
            rowCount={rowCount}
            minimumBatchSize={objects.data ? objects.data.lazyMinLimit : LAZY_MIN_LIMIT}
            threshold={objects.data ? objects.data.lazyThreshold : LAZY_THRESHOLD}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className={styles.list}
                    selected={objects.selected}
                    list={objects.list}
                    dict={objects.dict}
                    height={height}
                    width={width}
                    rowCount={rowCount}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    rowHeight={ROW_HEIGHT}
                    rowRenderer={this.renderRow}
                    scrollTop={resetScroll ? 0 : undefined}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        {footer && (
          <Footer
            publicProfiles={publicProfiles}
            objects={objects}
            filters={{
              fields: objects.request && objects.request.filter_fields,
              values: objects.request && objects.request.filter_values,
            }}
            accounts={accounts}
          />
        )}
      </div>
    );
  }
}

TableList.defaultProps = {
  loadRows: () => {},
};

export default TableList;
